import React, { useEffect, useState } from "react";
import styles from "./style.module.css";

import spbIcon from "../../assets/img/header/SP+B_WHITE.png";

const scrollIntoElement = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    if (event.target instanceof HTMLAnchorElement) {
        const elementToGo = document.querySelector(event.target.getAttribute("href") as string);

        elementToGo?.scrollIntoView({ behavior: "smooth", block: "start" });
    }
};

const setHeaderBackgroundByScroll = (event: Event) => {
    event.preventDefault();

    const headerElement = document.querySelector(`.${styles["content"]}`);
    const headerTop = headerElement?.getBoundingClientRect();
    const navElement = document.querySelector(`.${styles["nav"]} ul`);

    if (headerTop && navElement && headerTop.top < navElement.clientHeight * -1 * 2) {
        return navElement?.setAttribute("style", "background: rgba(237, 28, 143, .8); backdrop-filter: blur(5px);");
    }

    return navElement?.setAttribute("style", "background: transparent");
};

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleCloseMenu = () => setIsMenuOpen(false);
    const handleOpenMenu = () => setIsMenuOpen(true);

    useEffect(() => {
        window.addEventListener("scroll", setHeaderBackgroundByScroll);

        return () => {
            window.removeEventListener("scroll", setHeaderBackgroundByScroll);
        };
    }, []);

    return (
        <header>
            <div className={styles.content}>
                <div className={styles.container}>
                    <div className={styles["menu-mobile"]}>
                        <div className={styles["mobile-opener"]} onClick={handleOpenMenu}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        {isMenuOpen && (
                            <nav className={styles["menu-mobile-area"]}>
                                <span className={styles["mobile-closer"]} onClick={handleCloseMenu}>
                                    X
                                </span>
                                <ul>
                                    <li>
                                        <a href="#main" onClick={scrollIntoElement}>
                                            Sobre o SP+B
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#themes" onClick={scrollIntoElement}>
                                            Temas
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#extreme-collaboration" onClick={scrollIntoElement}>
                                            Colaboração Extrema
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#sp-proposal" onClick={scrollIntoElement}>
                                            Propostas para SP
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#form" onClick={scrollIntoElement}>
                                            Quero fazer parte
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#footer" onClick={scrollIntoElement}>
                                            Quem somos
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        )}
                    </div>
                    <nav className={styles.nav}>
                        <ul>
                            <li>
                                <a href="#main" onClick={scrollIntoElement}>
                                    Sobre o SP+B
                                </a>
                            </li>
                            <li>
                                <a href="#themes" onClick={scrollIntoElement}>
                                    Temas
                                </a>
                            </li>
                            <li>
                                <a href="#extreme-collaboration" onClick={scrollIntoElement}>
                                    Colaboração Extrema
                                </a>
                            </li>
                            <li>
                                <a href="#sp-proposal" onClick={scrollIntoElement}>
                                    Propostas para SP
                                </a>
                            </li>
                            <li>
                                <a href="#form" onClick={scrollIntoElement}>
                                    Quero fazer parte
                                </a>
                            </li>
                            <li>
                                <a href="#footer" onClick={scrollIntoElement}>
                                    Quem somos
                                </a>
                            </li>
                        </ul>
                    </nav>
                    <div className={styles["content-area"]}>
                        <div className={styles["logo-content"]}>
                            <img src={spbIcon} alt="Logo da SP+B" />
                        </div>
                        <div className={styles.description}>
                            <strong>
                                Acelerando o desenvolvimento <br /> sustentável em
                                <span data-tag> São Paulo</span>
                            </strong>
                        </div>
                    </div>
                    <div className={styles.linksArea}>
                        <div className={styles.linkContent}>
                            <a
                                href="https://drive.google.com/file/d/1HzmAdyraY2zrDPsmExi35C87NSZVopYY/view"
                                target="_blank"
                                rel="noreferrer"
                            >
                                BAIXE NOSSO RELATÓRIO DE ATIVIDADES 2023
                                <img src="/icons/download-icon.png" alt="Ícone de baixar relatório 2023." />
                            </a>
                        </div>
                        <div className={styles.linkContent}>
                            <a href="https://www.instagram.com/spmaisb/" target="_blank" rel="noreferrer">
                                <img src="/icons/instagram-icon.png" alt="Ícone do instragram." />
                                SIGA O @SPMAISB NO INSTAGRAM
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
