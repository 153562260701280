import styles from "./style.module.css";

import { FormEvent, useState } from "react";
import { saveForm } from "../../../firebase/api";
import { Payload, Sender } from "types/form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Topics from "./topics";

const initialState = {
    name: "",
    email: "",
    organization: "",
    message: "",
};

const SectionForm = () => {
    const [formInputs, setFormInputs] = useState(initialState);

    const [sender, setSender] = useState<Sender>({
        loading: false,
        success: false,
        error: false,
    });

    const handleError = () => {
        toast.error("Houve um erro ao enviar sua informações. Por favor, tente novamente.");
    };

    const handleSuccess = () => {
        toast.success("Dados enviados com sucesso!");
    };
    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        try {
            setSender((prev) => ({ ...prev, loading: true, error: false }));
            await saveForm(formInputs);

            setFormInputs(initialState);
            handleSuccess();
            setSender((prev) => ({ ...prev, loading: false, success: true }));
        } catch (error) {
            setSender({ loading: false, error: true, success: false });
            handleError();
            console.error(error);
        }
    };

    const handleChange =
        (type: keyof Payload) => (value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            return setFormInputs((prev) => ({ ...prev, [type]: value.target.value || "" }));
        };

    return (
        <section className={styles["section-form"]}>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                pauseOnHover
                theme="light"
            />
            <div className={styles.content}>
                <div id="sp-proposal">
                    <strong className={styles.title}>
                        PROPOSTAS <br /> PARA SP
                    </strong>
                    <Topics />
                </div>
                <div className={styles.container} id="form">
                    <h1 className={styles.formTitle}>QUERO FAZER PARTE</h1>
                    <div className={styles["form-area"]}>
                        <p className={styles.subtitle}>
                            Acreditamos que as melhores organizações melhoram a cidade. <br /> Vem
                            <span data-tag="somar"> somar</span> com a gente.
                        </p>
                        <form className={styles.form} onSubmit={handleSubmit}>
                            <div className={styles["input-wrapper"]}>
                                <label htmlFor="name">*Nome</label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    required
                                    onChange={handleChange.call(this, "name")}
                                    value={formInputs.name}
                                />
                            </div>
                            <div className={styles["input-wrapper"]}>
                                <label htmlFor="email">*E-mail</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    required
                                    onChange={handleChange.call(this, "email")}
                                    value={formInputs.email}
                                />
                            </div>
                            <div className={styles["input-wrapper"]}>
                                <label htmlFor="organization">*Organização</label>
                                <input
                                    type="text"
                                    id="organization"
                                    name="organization"
                                    required
                                    onChange={handleChange.call(this, "organization")}
                                    value={formInputs.organization}
                                />
                            </div>
                            <div className={styles["textarea"]}>
                                <div>
                                    <label htmlFor="message">*Como gostaria de se engajar com o SP+B?</label>
                                    <textarea
                                        name="message"
                                        id="message"
                                        rows={20}
                                        value={formInputs.message}
                                        required
                                        onChange={handleChange.call(this, "message")}
                                    />
                                </div>
                                <div className={styles.submit}>
                                    <button disabled={sender.loading} type="submit">
                                        {sender.loading ? "Enviando..." : "Enviar"}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SectionForm;
