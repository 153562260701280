import { useState } from "react";
import styles from "./style.module.css";
import { INITIAL_STATE_TOPICS } from "./utils";

const Topics = () => {
    const [topics, setTopics] = useState(INITIAL_STATE_TOPICS);

    const toggleTopic = (index: number) => {
        setTopics((prev) => {
            const newTopics = prev.map((topic, i) => (i === index ? { ...topic, open: !topic.open } : topic));

            return newTopics;
        });
    };

    return (
        <div className={styles.topicsContainer}>
            {topics.map((topic, index) => (
                <div key={index}>
                    <div className={styles.topic}>
                        <h2 className={styles.topicTitle}>{topic.title}</h2>
                        <button className={styles.topicButton} onClick={toggleTopic.bind(this, index)}>
                            <img
                                alt="Seta apontando para baixo: Clicável."
                                src="arrow-down.png"
                                style={{
                                    ...(Boolean(topic.open) && { transform: "rotate(180deg)" }),
                                }}
                            />
                        </button>
                    </div>
                    {topic.open && (
                        <ul className={styles.topicsUL}>
                            {topic.objectives.map((objective, index) => (
                                <li key={`objective_${index}`} className={styles.topicsList}>
                                    <div className={styles.topicObjectiveArea}>
                                        <div className={styles.topicObjective}>
                                            <h3 className={styles.topicObjectiveTitle}>{objective.title}</h3>
                                        </div>
                                        <div className={styles.topicObjectiveDescription}>
                                            <strong>Objetivo:</strong>
                                            <p>{objective.description}</p>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            ))}
        </div>
    );
};

export default Topics;
