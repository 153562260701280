import styles from "./style.module.css";

const SectionCollaboration = () => {
    return (
        <section className={styles.section} id="extreme-collaboration">
            <div className={styles.container}>
                <h1 className={styles.title}>
                    Colaboração <br /> Extrema
                </h1>
                <div className={styles.content}>
                    <div className={styles.description}>
                        <h2 className={styles.subtitle}>
                            Unimos muitos atores importantes em mais de 60 horas de reflexão.
                        </h2>
                        <div className={styles.countingArea}>
                            <div className={styles.counting}>
                                <span>
                                    + de <strong>200 reuniões</strong>
                                </span>
                                <div>
                                    <span>de articulação</span>
                                </div>
                            </div>
                            <div className={styles.counting}>
                                <span>
                                    + de <strong>100 Organizações</strong>
                                </span>
                                <div>
                                    <span>presentes</span>
                                </div>
                            </div>
                            <div className={styles.counting}>
                                <span>
                                    + de <strong>250 pessoas</strong>
                                </span>
                                <div>
                                    <span>envolvidas</span>
                                </div>
                            </div>
                            <div className={styles.counting}>
                                <span>
                                    + de <strong>15 empresas B</strong>
                                </span>
                            </div>
                            <div className={styles.counting}>
                                <span>
                                    + de <strong>20 Webinars</strong>
                                </span>
                                <div>
                                    <span>pré e entre laboratórios</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.imageContainer}>
                        <img
                            alt="Imagens de pessoas sorridentes."
                            srcSet="
                            extreme-collaboration-mobile.png 320w,
                            extreme-collaboration.png 769w
                            "
                            sizes="
                            (max-width: 768px) 100%,
                            (min-width: 1023px) 659px
                            "
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SectionCollaboration;
