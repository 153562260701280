import { getForm } from "../../firebase/api";
import MUIDataTable, { MUIDataTableColumn } from "mui-datatables";

import { useEffect, useState } from "react";

const columns = [
    {
        name: "name",
        label: "Nome",
        options: {
            customHeadLabelRender(options) {
                return <p style={{ fontFamily: "Gotham" }}>{options.label}</p>;
            },
        },
    },
    {
        name: "email",
        label: "E-mail",
        options: {
            customHeadLabelRender(options) {
                return <p style={{ fontFamily: "Gotham" }}>{options.label}</p>;
            },
        },
    },
    {
        name: "organization",
        label: "Organização",
        options: {
            customHeadLabelRender(options) {
                return <p style={{ fontFamily: "Gotham" }}>{options.label}</p>;
            },
        },
    },
    {
        name: "message",
        label: "Mensagem",
        options: {
            customHeadLabelRender(options) {
                return <p style={{ fontFamily: "Gotham" }}>{options.label}</p>;
            },
        },
    },
] as MUIDataTableColumn[];

const HiddenData = () => {
    const [dataColumns, setDataColumns] = useState([]);

    useEffect(() => {
        const bla = async () => {
            const data = await getForm();

            const arr: any = [];
            data?.forEach((item) => arr.push(item.data()?.data));

            setDataColumns(arr);
        };

        bla();
    }, []);

    return (
        <div style={{ maxWidth: "95%", margin: "80px auto", fontFamily: "Gotham" }}>
            <MUIDataTable
                options={{
                    download: true,
                    selectableRowsHideCheckboxes: true,
                    pagination: false,
                    print: false,
                    search: false,
                    elevation: 21,
                    filter: false,
                }}
                data={dataColumns}
                columns={columns}
                title="Dados cadastrados"
            />
        </div>
    );
};

export default HiddenData;
