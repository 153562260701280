import { initializeApp } from "firebase/app";
import { firebaseConfig } from "./firebaseConfig";
import { getFirestore, collection, addDoc, getDocs } from "firebase/firestore";
import { Payload } from "types/form";

const firebaseApp = initializeApp(firebaseConfig);

const db = getFirestore(firebaseApp);

export const saveForm = async (payload: Payload) => {
    try {
        const doc = await addDoc(collection(db, "new-form-data"), {
            data: payload,
        });

        return doc;
    } catch (error) {
        console.error(error);
    }
};

export const getForm = async () => {
    try {
        const dataDocs = await getDocs(collection(db, "new-form-data"));

        return dataDocs;
    } catch (e) {
        console.error(e);
    }
};
