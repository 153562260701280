import styles from "./style.module.css";

import mainThemeIcon1 from "assets/img/content/mainthemes/Group 28213.png";
import mainThemeIcon2 from "assets/img/content/mainthemes/Group 28214.png";
import mainThemeIcon3 from "assets/img/content/mainthemes/Group 28215.png";
import mainThemeIcon4 from "assets/img/content/mainthemes/Group 28216.png";
import mainThemeIcon5 from "assets/img/content/mainthemes/mundo 1.svg";

import tranverseThemeIcon1 from "assets/img/content/mainthemes/clima.svg";
import tranverseThemeIcon2 from "assets/img/content/mainthemes/cultura.svg";
import tranverseThemeIcon3 from "assets/img/content/mainthemes/diversidade.svg";
import tranverseThemeIcon4 from "assets/img/content/mainthemes/tech.svg";

const SectionMainThemes = () => {
    return (
        <section id="themes">
            <div className={styles.content}>
                <div className={styles.container}>
                    <strong className={styles.subtitle}>
                        TEMAS <span data-tag="PRINCIPAIS">PRINCIPAIS</span>
                    </strong>
                    <div className={styles.list}>
                        <ul>
                            <li>
                                <div className={styles["content-icon"]}>
                                    <img src={mainThemeIcon1} alt="Ícone habitação" />
                                </div>
                                <p>HABITAÇÃO</p>
                            </li>
                            <li>
                                <div className={styles["content-icon"]}>
                                    <img src={mainThemeIcon2} alt="Ícone segurança alimentar" />
                                </div>
                                <p>SEGURANÇA ALIMENTAR</p>
                            </li>
                            <li>
                                <div className={styles["content-icon"]}>
                                    <img src={mainThemeIcon3} alt="Ícone mobilidade urbana" />
                                </div>
                                <p>MOBILIDADE URBANA</p>
                            </li>
                            <li>
                                <div className={styles["content-icon"]}>
                                    <img src={mainThemeIcon4} alt="Ícone trabalho e renda" />
                                </div>
                                <p>
                                    TRABALHO <br />E RENDA
                                </p>
                            </li>
                            <li>
                                <div className={styles["content-icon"]}>
                                    <img src={mainThemeIcon5} alt="Ícone trabalho e renda" />
                                </div>
                                <p>
                                    ECONOMIA <br />
                                    CIRCULAR
                                </p>
                            </li>
                        </ul>
                    </div>
                    <strong className={styles.subtitle}>
                        TEMAS <br /> <span data-tag="TRANSVERSAIS">TRANSVERSAIS</span>
                    </strong>
                    <div className={styles.list}>
                        <ul>
                            <li>
                                <div className={styles["content-icon"]}>
                                    <img src={tranverseThemeIcon1} alt="Ícone clima" />
                                </div>
                                <p>CLIMA</p>
                            </li>
                            <li>
                                <div className={styles["content-icon"]}>
                                    <img src={tranverseThemeIcon2} alt="Ícone cultura" />
                                </div>
                                <p>CULTURA</p>
                            </li>
                            <li>
                                <div className={styles["content-icon"]}>
                                    <img src={tranverseThemeIcon3} alt="Ícone diversidade" />
                                </div>
                                <p>DIVERSIDADE</p>
                            </li>
                            <li>
                                <div className={styles["content-icon"]}>
                                    <img src={tranverseThemeIcon4} alt="Ícone tecnologia e inovação" />
                                </div>
                                <p>TECNOLOGIA E INOVAÇÃO</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SectionMainThemes;
