import SectionCollaboration from "./SectionCollaboration";
import SectionForm from "./SectionForm";
import SectionObjective from "./SectionObjective";
import SectionMainThemes from "./SectionTheme";

const Content = () => {
    return (
        <main id="main-content">
            <SectionObjective />
            <SectionMainThemes />
            <SectionCollaboration />
            <SectionForm />
        </main>
    );
};

export default Content;
