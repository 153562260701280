import styles from "./style.module.css";

const SectionObjective = () => {
    return (
        <section id="main" className={styles.section}>
            <div className={styles.container}>
                <div className={styles.objectiveContainer}>
                    <div className={styles.imageContainer}>
                        <img
                            width={501}
                            height={397}
                            alt="Imagem de uma rodovia com uma cidade de fundo."
                            src="objective.png"
                        />
                    </div>
                    <div className={styles.descriptionContainer}>
                        <div className={styles.spbContainer}>
                            <span className={styles.spb}>SP+B</span>
                        </div>
                        <h3 className={styles.objectiveTitle}>
                            O <span>SP+B</span> tem como objetivo a{" "}
                            <span>cocriação de futuros desejáveis para a cidade</span> de São Paulo:
                        </h3>
                        <p className={styles.objectiveDescription}>
                            Juntos estamos ressignificando a definição de cidades inteligentes tal como conhecemos no
                            senso comum, e
                            <strong>
                                {" "}
                                desenvolvendo de forma colaborativa uma visão sistêmica de impacto positivo
                            </strong>{" "}
                            através da colaboração extrema e do entrelaçamento de stakeholders.
                        </p>
                    </div>
                </div>
                <div className={styles.videoContainer}>
                    <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/lBislOdE8_c?si=-BxGFP3RVMkU1jo4"
                        title="YouTube video player"
                        allowFullScreen
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    ></iframe>
                </div>
            </div>
        </section>
    );
};

export default SectionObjective;
