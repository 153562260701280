export const INITIAL_STATE_TOPICS = [
    {
        title: "HABITAÇÃO",
        objectives: [
            {
                title: "Lidera Moradia",
                description:
                    "Conteúdos que apoiem líderes comunitários no engajamento e desenvolvimento em seu território.",
            },
        ],
        open: false,
    },
    {
        title: "MOBILIDADE URBANA",
        objectives: [
            { title: "Empresas pela mobilidade", description: "Fomentar a intermodalidade em empresas." },
            { title: "Dados em movimento", description: "Acabar com a invisibilidade de grupos vulnerabilizados." },
            {
                title: "Conexão Centro Periferia",
                description: "Engajar a população a pensar em trajetos de forma comunitária.",
            },
        ],
        open: false,
    },
    {
        title: "SEGURANÇA ALIMENTAR",
        objectives: [
            {
                title: "Alimentando a mudança",
                description:
                    "Aprofundar o conhecimento de quem está em situação de fome e insegurança alimentar e mapear quais iniciativas e ações são capazes de atender essa população.",
            },
            {
                title: "Programa Super Agricultor SP",
                description:
                    "Fortalecer agricultores urbanos em diferentes esferas: material e humana, para retenção e atração de jovens.",
            },
            {
                title: "Mãos da terra, mãos do prato",
                description:
                    "Alimentação gratuita para famílias em vulnerabilidade, com formações nas etapas do processo para mulheres, prioridade para mães solo.",
            },
        ],
        open: false,
    },
    {
        title: "TRABALHO E RENDA",
        objectives: [
            { title: "Empodera tech", description: "Mapear e analisar tecnologias sociais e ofertas de trabalho." },
            { title: "Contratação legal", description: "Fomentar a inclusão produtiva territorial." },
            {
                title: "Guia do futuro do trabalho",
                description:
                    "Alinhar conceitos e reeducar instituições públicas e privadas para que possam ter acesso a um novo glossário para trabalhar, não só de palavras, mas de método. ",
            },
        ],
        open: false,
    },
    {
        title: "ECONOMIA CIRCULAR",
        objectives: [
            {
                title: "ReShopping",
                description:
                    "Mapear, ampliar e promover negócios e projetos circulares na região da Zona Norte de São Paulo, podendo expandir para outros territórios da cidade.",
            },
            {
                title: "Semente Circular",
                description:
                    "Capacitar educadores do Ensino Fundamental 1 como agentes ambientais, através da escuta atenta e valorização das potências locais, promovendo a economia circular no território.",
            },
        ],
        open: false,
    },
];
